import "./App.css";
import FetchHomechan from "./FetchHomechan";
import { FaXTwitter } from "react-icons/fa6";
import { IconContext } from "react-icons";

function App() {
  return (
    <div>
      <header>
        <div className="header font-yuseiMagic">
          <p>いっぱい褒めるほめちゃんだよ！</p>
        </div>
      </header>
      <div className="body">
        <FetchHomechan></FetchHomechan>
      </div>
      <footer className="footer">
        <IconContext.Provider value={{ size: "20px" }}>
          <div className="flex justify-center mt-6 gap-4">
            <a href="https://twitter.com/Mw1FIDsnqdLbyDs" className="hover:text-[#282c34] rumble" target="_blank" rel="noreferrer">
              <FaXTwitter />
            </a>
          </div>
        </IconContext.Provider>
        <p>© 2024 たつや、</p>
      </footer>
    </div>
  );
}

export default App;
