// useEffectをインポートしておく
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import "./FetchHomechan.css";
import CircularProgress from "@mui/material/CircularProgress";
import { MdKeyboardVoice } from "react-icons/md";
import { IconContext } from "react-icons";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

function FetchHomechan() {
  const [homechanMessage, setHomechanMessage] = useState("今日は何をしたのかな？");
  const [yourMessage, setYourMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { transcript, listening } = useSpeechRecognition();

  useEffect(() => {
    if (listening) setYourMessage(transcript);
  }, [listening, transcript]);

  const handleClick = async () => {
    if (!yourMessage) {
      setHomechanMessage("ほめちゃんに褒めてもらいたくないの…？");
      setYourMessage("");
      return;
    }
    setIsLoading(true);
    const url = "https://api-mebo.dev/api";
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ api_key: "53166f2a-d35f-458e-9bf6-b19d2075a99d18d319a763a162", agent_id: "0fe6c38a-fe67-43c6-ba8b-08241e58b35918d31104f4450", utterance: yourMessage }),
    };
    const response = await fetch(url, options);
    const body = await response.json();
    setHomechanMessage(body.bestResponse.utterance);
    // setHomechanMessage(
    //   "ほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモックほめちゃんモック"
    // );
    setYourMessage("");
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setYourMessage(event.target.value);
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      handleClick();
    }
  };

  return (
    <div className="homechan grid xl:grid-cols-8 grid-cols-6">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={listening}>
        <MdKeyboardVoice color="#F6B17A" className="animate-bounce" size="20%" />
      </Backdrop>
      <div className="xl:col-start-4 xl:col-span-2 col-start-2 col-span-4">
        <div className="homechanImage">
          <img alt="ほめちゃん" src={`${process.env.PUBLIC_URL}/ほめちゃん.png`}></img>
        </div>
      </div>
      <div className="xl:col-start-3 xl:col-span-4 col-start-2 col-span-4 homechanMessage font-yuseiMagic balloon">
        <p>{homechanMessage}</p>
      </div>
      <div className="xl:col-start-3 xl:col-span-4 col-start-2 col-span-4">
        <TextField margin="normal" onKeyDown={(e) => onKeyUp(e)} id="outlined-multiline-static" multiline fullWidth rows={5} value={yourMessage} onChange={handleChange} InputProps={{ sx: { borderRadius: "10px" } }} />
      </div>
      <div className="xl:col-start-3 xl:col-span-4 col-start-2 col-span-4 flex justify-center items-center">
        <IconContext.Provider value={{ size: "40px", color: "#F6B17A" }}>
          <MdKeyboardVoice onClick={() => SpeechRecognition.startListening()} />
        </IconContext.Provider>
      </div>
      <div className="xl:col-start-3 xl:col-span-4 col-start-2 col-span-4 flex justify-center items-center">
        <Button
          variant="contained"
          onClick={handleClick}
          style={{
            backgroundColor: "#E6A4B4",
          }}
          sx={{ m: 1 }}
        >
          褒めてもらう！
        </Button>
      </div>
    </div>
  );
}

export default FetchHomechan;
